import { CatalogTitle, fetchTitles } from '@/services/ContentCatalog'
import { isContentExperimentModel, isHasTypeName, PageDataContext, PageModel } from '@/services/RenderService'
import { CollectionDisplay, Maybe, Page, PageContentItem, TitleCollection } from '@/types/codegen-contentful'
import { logger } from '@/utils/logging'
import { isDefined } from '@/utils/types'
import { isCollectionDisplay } from '../CollectionDisplay'

export function isRequiresTitleMapOnPage(page: Page) {
  return (
    checkPageCollectionDisplayWithTitleCollection(page) ||
    checkContentExperimentHasCollectionDisplayAndTitleCollection(page)
  )
}

function checkPageCollectionDisplayWithTitleCollection(page: PageModel): boolean {
  return (
    page.contentCollection?.items.some((item) => isCollectionDisplay(item) && isTitleCollection(item.collection)) ??
    false
  )
}

function checkContentItemCollectionDisplayWithTitleCollection(contentItem: PageContentItem): boolean {
  return isCollectionDisplay(contentItem) && isTitleCollection(contentItem.collection)
}

function checkContentExperimentHasCollectionDisplayAndTitleCollection(page: PageModel): boolean {
  return (
    page.contentCollection?.items
      ?.filter(isContentExperimentModel)
      .flatMap((contentExperiment) => contentExperiment.variantsCollection?.items)
      .some((variant) =>
        variant?.contentItemsCollection?.items
          .filter(isDefined)
          .some((item) => checkContentItemCollectionDisplayWithTitleCollection(item)),
      ) ?? false
  )
}

export function isTitleCollection(content: unknown): content is TitleCollection {
  return isHasTypeName(content) && content.__typename === 'TitleCollection'
}

export async function fetchTitlesForPage(page: Page): Promise<CatalogTitle[]> {
  const ids = mapAllContentIds(page)
  const experimentIds = mapExperimentContentIds(page)
  const idsToFetch = ids.concat(experimentIds)

  const titles = await fetchTitles(
    // TODO: get a dynamic locale once pages or collections (to be designed) are regionalized
    'en',
    idsToFetch,
  )

  return titles
}

function mapAllContentIds(page: Page) {
  return (
    page.contentCollection?.items
      .filter(isCollectionDisplay)
      .map((collectionDisplay) => collectionDisplay.collection)
      .filter(isTitleCollection)
      .map((titleCollection) => titleCollection.contentCatalogIds)
      .flat()
      .filter(isDefined) || []
  )
}

function mapExperimentContentIds(page: Page) {
  return (
    page.contentCollection?.items
      .filter(isContentExperimentModel)
      .flatMap((contentExperiment) => contentExperiment.variantsCollection?.items)
      .flatMap((variant) => variant?.contentItemsCollection?.items)
      .filter((contentItem) => isCollectionDisplay(contentItem))
      .map((contentItem) => contentItem as CollectionDisplay)
      .filter(isDefined)
      .map((collectionDisplay) => collectionDisplay?.collection)
      .filter((collection) => isTitleCollection(collection))
      .map((collection) => collection?.contentCatalogIds)
      .filter(isDefined)
      .flat()
      .filter(isDefined) || []
  )
}

export function mapContentIdsToTitles(pageDataContext: PageDataContext, ids?: Maybe<Maybe<string>[]>): CatalogTitle[] {
  return (
    ids
      ?.map((id) => {
        if (!id) return undefined

        const titleMap = pageDataContext['title-map']
        if (!titleMap) {
          logger().error(`The "title-map" property is missing from the pageDataContext!`)
          return undefined
        }

        const catalogTitle = titleMap[id]
        if (!catalogTitle) {
          logger().warn(`Expected to find a catalog title from the "title-map" by id, but found nothing!`, { id })
          return undefined
        }

        return catalogTitle
      })
      .filter(isDefined) || []
  )
}
